.header {
    height: 80px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.logo {
    height: 70%;
    margin-left: 20px;
    margin-right: 20px;
}
.right {
    margin-left: auto;
}
.logout{
    display: flex;
    height: 30px;
    width: 100px;
    margin-right: 0px;
    font-size: 15px;
    text-align: center;
    text-anchor: middle;
    align-items: center;
    justify-content: center;
    background-color: #a1b8d3;
    margin-top: 12px;
    outline: 1px solid white
}
.col{
    padding-left: 5px;
    padding-right: 5px;
}