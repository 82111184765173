.container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  flex-grow: 1;
  background-color: #a1b8d3;
  height: 100%;
}
.containerHeadline {
  margin: 5px;
  margin-left: 20px;
  color: white;
  font-size: 14px;
}
.baseView {
  margin: 20px;
  padding: 20px;
  margin-top: 0px;
  flex-grow: 1;
  background-color: #ffffff;
}
